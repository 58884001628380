'use client';

import { createContext, useContext } from 'react';
import type { ReactNode } from 'react';

type Config = {
  [key: string]: unknown;
};
type Props = {
  children: ReactNode;
  value: Config;
};

const ConfigContext = createContext<Config>({});

export function ConfigProvider({ children, value }: Props): JSX.Element {
  return <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>;
}

export function useConfig(): Config {
  return useContext(ConfigContext);
}
