import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import LocationOffOutlinedIcon from '@mui/icons-material/LocationOffOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ResultSkeleton from './result-skeleton';
import type { PlaceType, Store } from './types';

type Props = {
  className?: string;
  handleStoreClick: (store: Store) => void;
  inputValue: string;
  isLoading: boolean;
  noLocationsFoundMsg: string;
  selectedStoreId: string | null;
  stores: Store[];
  value: PlaceType | null;
  textColor?: string;
};

export default function ResultsList({
  className,
  handleStoreClick,
  inputValue,
  isLoading,
  noLocationsFoundMsg,
  selectedStoreId,
  stores,
  value,
  textColor,
}: Props): JSX.Element {
  const loadingSkeletonCount = Array.from({ length: 8 }, (_v, i) => i + 1);

  return (
    <>
      {isLoading ? (
        <div className={className}>
          {loadingSkeletonCount.map((key) => (
            <ResultSkeleton key={key} />
          ))}
        </div>
      ) : (
        <>
          {stores.length === 0 && inputValue && value !== null ? (
            <div className={`${className} flex !flex-row p-4 text-base`}>
              <LocationOffOutlinedIcon className="mr-2" /> {noLocationsFoundMsg}
            </div>
          ) : (
            <List className={className}>
              {stores.map((store) => (
                <ListItem disablePadding key={store.id} sx={{ borderBottom: '1px solid #d9d9d9' }}>
                  <ListItemButton
                    // eslint-disable-next-line jsx-a11y/no-autofocus -- autoFocus is needed for keyboard navigation
                    autoFocus={selectedStoreId === store.id}
                    className="py-1 px-3"
                    disableRipple
                    onClick={() => {
                      handleStoreClick(store);
                    }}
                    selected={selectedStoreId === store.id}
                  >
                    <ListItemIcon className="self-start min-w-[2rem] pt-2">
                      <LocationOnOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                      className={textColor}
                      primary={store.name}
                      primaryTypographyProps={{
                        padding: '0 0 0.25rem',
                        fontSize: 16,
                        fontWeight: 700,
                        lineHeight: '1.25rem',
                      }}
                      secondary={
                        <>
                          <span className="block text-sm">{store.address}</span>
                          <span className={`flex items-center pt-1 text-md ${textColor}`}>
                            <LocalPhoneOutlinedIcon className="pr-1" />
                            {store.phone}
                          </span>
                        </>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          )}
        </>
      )}
    </>
  );
}
