'use client';

import { useCallback, useEffect, useId, useMemo, useRef, useState } from 'react';
import type { KeyboardEvent, LegacyRef } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import MyLocationOutlinedIcon from '@mui/icons-material/MyLocationOutlined';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { debounce } from '@mui/material/utils';
import parse from 'autosuggest-highlight/parse';
import { cn } from '../../utils/tailwind-merge';
import { mapStyles } from './map-styles';
import { InfoWindowContent } from './info-window-content';
import ResultsList from './results-list';
import type { PlaceType, Store } from './types';

type Props = {
  brand: string;
  buttonClassname?: string;
  className?: string;
  countryCode: string;
  inputAriaLabel: string;
  inputClassname?: string;
  mapCenterLatLng?: { lat: number; lng: number };
  noLocationsFoundMsg: string;
  noOptionsText: string;
  onStoreSearch?: () => void;
  placeholder: string;
  searchButtonAriaLabel: string;
  showAmbassadorStoresOnly?: boolean;
  title: string;
  useMyLocationText: string;
  variant?: 'compact' | 'regular';
  textColor?: string;
  backgroundColor?: string;
  pinBackgroundColor?: string;
};

const autocompleteService: {
  current: google.maps.places.AutocompleteService | null;
} = { current: null };

const DEFAULT_MAP_CENTER = { lat: 41.39410633058588, lng: 2.162278452626236 };

export function StoreLocator({
  brand,
  buttonClassname,
  className,
  countryCode,
  inputAriaLabel,
  inputClassname,
  mapCenterLatLng,
  noLocationsFoundMsg,
  noOptionsText,
  placeholder,
  searchButtonAriaLabel,
  showAmbassadorStoresOnly,
  title,
  useMyLocationText,
  variant,
  onStoreSearch,
  textColor = 'text-primary',
  backgroundColor = 'bg-primary',
  pinBackgroundColor = '#004888',
}: Props): JSX.Element | null {
  const inputRef = useRef<HTMLInputElement>(null);
  const mapRef = useRef<HTMLElement>(null);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [markers, setMarkers] = useState<{
    [id: string]: google.maps.marker.AdvancedMarkerElement | google.maps.Marker;
  }>({});
  const [infoWindow, setInfoWindow] = useState<google.maps.InfoWindow | null>(null);
  const [stores, setStores] = useState<Store[] | []>([]);
  const [selectedStoreId, setSelectedStoreId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasGoogleMapsLoaded, setHasGoogleMapsLoaded] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<PlaceType[] | []>([]);
  const [value, setValue] = useState<PlaceType | null>(null);
  const [open, setOpen] = useState(false);
  const inputId = useId();
  const initialMapCenter = mapCenterLatLng ?? DEFAULT_MAP_CENTER;

  /**
   * Fetch predictions from Google Maps API
   * The debounce function prevents the API from being called on every keystroke
   */
  const fetchPredictions = useMemo(
    () =>
      debounce(
        (
          request: { input: string },
          callback: (
            a: google.maps.places.AutocompletePrediction[] | null,
            b: google.maps.places.PlacesServiceStatus
          ) => void
        ) => {
          autocompleteService.current
            ?.getPlacePredictions(request, callback)
            .then(() => {
              // then method not needed - added to prevent linter error
            })
            .catch(() => {
              // catch method not needed - added to prevent linter error
            });
        },
        400
      ),
    []
  );

  /**
   * Fetch stores from the API, draw markers on the map and define the info window and the click event for each marker
   */
  const searchStores = useCallback(
    (lat: number, lng: number): void => {
      fetchStores({ brand, countryCode, distance: 100, lat, lng, showAmbassadorStoresOnly })
        .then((storesArray) => {
          setStores(storesArray);
          setIsLoading(false);

          storesArray.map((store: Store) => {
            const pinBackground = new window.google.maps.marker.PinElement({
              background: pinBackgroundColor,
              borderColor: 'white',
              glyphColor: 'white',
            });
            const marker = new window.google.maps.marker.AdvancedMarkerElement({
              map,
              position: { lat: store.latLng[0], lng: store.latLng[1] },
              title: store.name,
              content: pinBackground.element,
            });
            setMarkers((previousMarkers) => ({ ...previousMarkers, [store.id]: marker }));

            marker.addListener('click', () => {
              infoWindow?.close();
              infoWindow?.setContent(InfoWindowContent({ store, textColor }));
              setSelectedStoreId(store.id);
              infoWindow?.open({
                anchor: marker,
                map,
              });
            });

            return null;
          });

          infoWindow?.close();
          map?.setCenter({ lat, lng });
        })
        .catch(() => {
          setIsLoading(false);
          setStores([]);
        });
    },
    [brand, countryCode, infoWindow, map, pinBackgroundColor, showAmbassadorStoresOnly]
  );

  useEffect(() => {
    if (inputValue && inputValue.length > 0) openSearch(false);
  }, [inputValue]);

  /**
   * Fetch stores whenever a place is selected from the autocomplete dropdown
   * It uses the place_id to get the lat/lng of the place
   */
  useEffect(() => {
    if (!value || typeof value === 'string') {
      return;
    }

    const geocoder = new window.google.maps.Geocoder();
    geocoder
      .geocode({ placeId: value.place_id })
      .then(({ results }) => {
        if (results.length > 0) {
          const { location } = results[0]?.geometry ?? {
            location: { lat: () => initialMapCenter.lat, lng: () => initialMapCenter.lng },
          };
          const lat = location.lat();
          const lng = location.lng();

          setIsLoading(true);
          searchStores(lat, lng);
        }
      })
      .catch((_error) => {
        setStores([]);
      });
  }, [initialMapCenter, searchStores, value]);

  /**
   * Fetch predictions from Google Maps API whenever the input value changes
   */
  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && hasGoogleMapsLoaded) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetchPredictions({ input: inputValue }, (results) => {
      if (active) {
        let newOptions: [PlaceType, ...PlaceType[]] | PlaceType[] = value ? [value] : [];

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [fetchPredictions, hasGoogleMapsLoaded, inputValue, value]);

  /**
   * Load Google Maps API
   */
  useEffect(() => {
    if (hasGoogleMapsLoaded || !open) {
      return;
    }

    const loader = new Loader({
      apiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY ?? '',
      version: 'weekly',
      libraries: ['maps', 'marker', 'places'],
    });
    loader
      .importLibrary('maps')
      .then(() => {
        setHasGoogleMapsLoaded(true);
        const mapInstance = new window.google.maps.Map(mapRef.current ?? document.createElement('div'), {
          center: initialMapCenter,
          fullscreenControl: false,
          mapId: 'b2937685803783bf',
          streetViewControl: false,
          styles: mapStyles,
          zoom: 14,
        });

        setMap(mapInstance);
        setInfoWindow(new window.google.maps.InfoWindow());
      })
      .catch((_error) => {
        setHasGoogleMapsLoaded(false);
      });
  }, [hasGoogleMapsLoaded, initialMapCenter, open]);

  useEffect(() => {
    if (!hasGoogleMapsLoaded || !map) {
      return;
    }

    if (!inputValue) {
      const { lat, lng } = initialMapCenter;
      // fetch the initial stores as per the default map center
      searchStores(lat, lng);
    }

    // add event the fetch a new set of stores whenever the map is dragged
    map.addListener('dragend', () => {
      const lat = map.getCenter()?.lat();
      const lng = map.getCenter()?.lng();

      if (lat && lng) {
        setValue(null);
        setIsLoading(true);
        searchStores(lat, lng);
      }
    });
  }, [hasGoogleMapsLoaded, initialMapCenter, inputValue, map, searchStores]);

  function openSearch(withOnStoreSearch = true): void {
    if (withOnStoreSearch) {
      onStoreSearch?.();
    }
    setOpen(true);
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  }

  function handleClose(): void {
    setOpen(false);
  }

  function handleLocation(): void {
    setIsLoading(true);
    setInputValue(useMyLocationText);
    setValue(null);
    navigator.geolocation.getCurrentPosition(
      (geolocation) => {
        const lat = geolocation.coords.latitude;
        const lng = geolocation.coords.longitude;
        onStoreSearch?.();
        searchStores(lat, lng);
      },
      (_error) => {
        setIsLoading(false);
        setStores([]);
      }
    );
  }

  function handleEnter(event: KeyboardEvent<HTMLInputElement>): void {
    if (event.key === 'Enter') {
      event.stopPropagation();
      openSearch();
    }
  }

  function handleStoreClick(store: Store): void {
    setSelectedStoreId(store.id);
    infoWindow?.close();
    infoWindow?.setContent(InfoWindowContent({ store, textColor }));
    infoWindow?.open(map, markers[store.id]);
    map?.setCenter({ lat: store.latLng[0], lng: store.latLng[1] });
  }

  return (
    <>
      <Paper
        className={cn(
          `flex w-full items-center border border-solid border-primary overflow-hidden ${variant === 'compact' ? 'mt-2' : 'mt-4'}`,
          className
        )}
        component="form"
        elevation={0}
      >
        <InputBase
          autoComplete="postal-code"
          className={cn(`flex-grow px-4 text-lg ${textColor}`, inputClassname)}
          inputProps={{
            'aria-label': inputAriaLabel,
          }}
          name="postal-code"
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          onKeyDown={handleEnter}
          placeholder={placeholder}
          value={inputValue}
        />
        <Button
          aria-label={searchButtonAriaLabel}
          className={cn(
            `h-full text-white ${backgroundColor} hover:${backgroundColor} rounded-none ${
              variant === 'compact' ? 'py-[0.6875rem] px-3' : 'py-3.5 px-5'
            } flex-none w-auto min-w-[50px]`,
            buttonClassname
          )}
          color="primary"
          onClick={() => {
            openSearch();
          }}
        >
          <ArrowForwardOutlinedIcon />
        </Button>
      </Paper>
      <Dialog
        aria-describedby="keep-mounted-modal-description"
        aria-labelledby="keep-mounted-modal-title"
        fullWidth
        keepMounted
        maxWidth="xl"
        onClose={handleClose}
        open={open}
        sx={(theme) => ({
          '& .MuiDialog-paper': {
            width: '100%',
            margin: '1rem',
            [theme.breakpoints.up('md')]: {
              margin: '2.5rem',
              height: '90vh',
            },
            [theme.breakpoints.up('lg')]: {
              margin: '4rem',
              maxHeight: '1200px',
            },
          },
        })}
      >
        <div className="relative flex flex-col align-stretch w-full h-full md:flex-row md:overflow-hidden">
          <IconButton
            aria-label="close"
            className="absolute top-3 right-3 bg-white z-10"
            onClick={handleClose}
            size="small"
          >
            <CloseOutlinedIcon />
          </IconButton>
          <div className="flex flex-col w-full md:w-[38rem] p-2.5 pt-6 md:p-4">
            <p className={`pb-3 text-lg md:text-base ${textColor}`}>{title}</p>
            <Autocomplete
              autoComplete
              filterOptions={(x) => x}
              filterSelectedOptions
              getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
              id={inputId}
              inputValue={inputValue}
              noOptionsText={noOptionsText}
              onChange={(_event, newValue) => {
                if (newValue) {
                  // avoid to send event when the new value is null this happen when user clean the input
                  onStoreSearch?.();
                }
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
              }}
              onInputChange={(_event, newInputValue, reason) => {
                if (reason !== 'reset') {
                  setInputValue(newInputValue);
                }
              }}
              options={options}
              renderInput={(params) => (
                <>
                  <label className="hidden" htmlFor={inputId}>
                    {title}
                  </label>
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title={useMyLocationText}>
                            <IconButton aria-label={useMyLocationText} edge="end" onClick={handleLocation}>
                              <MyLocationOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    inputRef={inputRef}
                    placeholder={placeholder}
                  />
                </>
              )}
              renderOption={(props, option) => {
                const matches = option.structured_formatting.main_text_matched_substrings || [];

                const parts = parse(
                  option.structured_formatting.main_text,
                  matches.map((match) => [match.offset, match.offset + match.length])
                );
                const addressParts = parts.map((part) => ({ ...part, id: `id-${Math.random().toString()}` }));

                return (
                  <li {...props} key={props.id}>
                    <div className="flex">
                      <LocationOnOutlinedIcon sx={{ color: 'text.secondary' }} />
                    </div>
                    <div className="px-2">
                      {addressParts.map((part) => (
                        <span className={part.highlight ? 'font-bold' : ''} key={part.id}>
                          {part.text}
                        </span>
                      ))}
                      <p className="text-sm">{option.structured_formatting.secondary_text}</p>
                    </div>
                  </li>
                );
              }}
              sx={{
                '& .MuiInputBase-root': {
                  paddingRight: '1.25rem !important',
                },
              }}
              value={value}
            />
            <ResultsList
              className="hidden md:flex flex-col mt-2 overflow-y-auto"
              handleStoreClick={handleStoreClick}
              inputValue={inputValue}
              isLoading={isLoading}
              noLocationsFoundMsg={noLocationsFoundMsg}
              selectedStoreId={selectedStoreId}
              stores={stores}
              textColor={textColor}
              value={value}
            />
          </div>
          <div
            className="flex w-full h-[400px] min-h-[400px] md:h-auto md:min-h-full bg-accent-2"
            ref={mapRef as LegacyRef<HTMLDivElement>}
          />
          <ResultsList
            className="md:hidden flex flex-col mt-2 max-h-[18rem] overflow-y-auto"
            handleStoreClick={handleStoreClick}
            inputValue={inputValue}
            isLoading={isLoading}
            noLocationsFoundMsg={noLocationsFoundMsg}
            selectedStoreId={selectedStoreId}
            stores={stores}
            textColor={textColor}
            value={value}
          />
        </div>
      </Dialog>
    </>
  );
}

async function fetchStores({
  brand,
  countryCode,
  distance = 100,
  lat,
  lng,
  showAmbassadorStoresOnly,
}: {
  brand?: string;
  countryCode: string;
  distance?: number;
  lat: number;
  lng: number;
  showAmbassadorStoresOnly?: boolean;
}): Promise<Store[] | []> {
  const baseUrl = process.env.NEXT_PUBLIC_STORE_LOCATOR_URL ?? '';
  const results: Store[] | [] = await fetch(
    `${baseUrl}?lat=${lat}&lng=${lng}&countryCode=${countryCode}&brand=${brand}&distance=${distance}${showAmbassadorStoresOnly ? '&isAmbassador=true' : ''}`,
    {
      method: 'GET',
    }
  ).then((response) => response.json());

  return results;
}
