'use client';

import { useEffect, useState } from 'react';
import cookie from 'js-cookie';
import { gtag } from './script';

declare global {
  interface Window {
    dataLayer?: { [key: string]: unknown }[];
  }
}

export type Preference = 'ads' | 'analytics' | 'none' | 'all';
export type ExtendedConsentType = 'granted' | 'denied';

const COOKIE_NAME = 'cookie-consent';

export function useConsentPreferences(): [string | undefined, (preference: Preference) => void] {
  const [consentedStorages, setConsentedStorages] = useState<string | undefined>();

  useEffect(() => {
    const consentCookieValue = cookie.get(COOKIE_NAME);
    const dataLayerHasConsentUpdate = window.dataLayer?.find((item) => item[0] === 'consent' && item[1] === 'update');

    if (consentCookieValue) {
      setConsentedStorages(consentCookieValue);
    }

    if (!dataLayerHasConsentUpdate) {
      updateGtmConsent(consentCookieValue);
    }
  }, []);

  function savePreferences(preference: Preference): void {
    const preferences = {
      ads: 'ad_storage,ad_user_data,ad_personalization',
      analytics: 'analytics_storage',
      none: 'none',
      all: 'ad_storage,ad_user_data,ad_personalization,analytics_storage',
    };

    if (!preferences[preference]) return;

    cookie.set(COOKIE_NAME, preferences[preference], {
      expires: 365,
      secure: process.env.NODE_ENV === 'production',
    });

    updateGtmConsent(preferences[preference]);
  }

  return [consentedStorages, savePreferences];
}

function getCookiePreferences(consentCookieValue: string | undefined): string[] {
  const preferences = consentCookieValue ? consentCookieValue.replace(/ /g, '').split(',') : [];

  return preferences;
}

function updateGtmConsent(consentCookieValue: string | undefined): void {
  const preferences = getCookiePreferences(consentCookieValue);
  if (typeof window.dataLayer === 'undefined') return;

  gtag('consent', 'update', {
    ad_storage: preferences.includes('ad_storage') ? 'granted' : 'denied',
    ad_user_data: preferences.includes('ad_user_data') ? 'granted' : 'denied',
    ad_personalization: preferences.includes('ad_personalization') ? 'granted' : 'denied',
    analytics_storage: preferences.includes('analytics_storage') ? 'granted' : 'denied',
  });

  if (preferences.includes('analytics_storage') || preferences.includes('ad_storage')) {
    setTimeout(() => {
      // @ts-expect-error -- this event is required by Google Analytics to start tracking without a page reload. Being a GA event, it´s not typed in GTM.
      window.dataLayer.push({
        event: 'update_consent',
      });
    }, 2000);
  }
}

type SetPreferencesParam = (preferences: string) => void;

type SavePreferencesParam = (consentType: 'all' | 'none') => void;

export function updateConsentPreferences(
  hasAgreed: boolean,
  setPreferences: SetPreferencesParam,
  savePreferences: SavePreferencesParam
): void {
  const consentPreferences = hasAgreed
    ? ['analytics_storage', 'ad_storage', 'ad_personalization', 'ad_user_data'].join(',')
    : 'none';

  setPreferences(consentPreferences);

  savePreferences(hasAgreed ? 'all' : 'none');
}
