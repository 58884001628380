import type { PortableTextReactComponents } from '@portabletext/react';

/**
 * Serializers for rendering custom PortableText blocks from Sanity
 * https://www.sanity.io/guides/portable-text-internal-and-external-links
 */
export const customSerializers: Partial<PortableTextReactComponents> = {
  marks: {
    internalLink: ({ value, children }: { value?: { slug: { current: string } }; children: React.ReactNode }) => {
      const { slug } = value ?? {};
      const href = `/${slug?.current}`;

      return <a href={href}>{children}</a>;
    },
    link: ({ value, children }: { value?: { blank: boolean; href: string }; children: React.ReactNode }) => {
      if (value?.blank) {
        return (
          <a href={value.href} rel="noopener noreferrer" target="_blank">
            {children}
          </a>
        );
      }
      return <a href={value?.href}>{children}</a>;
    },
  },
};
