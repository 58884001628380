'use client';

import { useEffect, useState } from 'react';
import type { SyntheticEvent } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { PortableText } from '@portabletext/react';
import { customSerializers } from '../custom-sanity-serializers';
import { ConfigSwitch } from './switch';
import { updateConsentPreferences, useConsentPreferences } from './use-consent-preferences';
import { useCookieConfigModal } from './context';
import type { Preference } from './use-consent-preferences';
import type { GtmConfig } from './types';

type Props = {
  config: GtmConfig;
};

export default function ConfigModal({ config }: Props): JSX.Element | null {
  const {
    isConfigOpen,
    isConfigOpenedFromConsent,
    setIsConfigOpen,
    setIsConsentModalOpen,
    setIsConfigOpenedFromConsent,
    preferences,
    setPreferences,
  } = useCookieConfigModal();
  const [value, setValue] = useState(0);
  const [expanded, setExpanded] = useState<string | false>('');
  const [consentedStorages, savePreferences] = useConsentPreferences();

  useEffect(() => {
    if (typeof consentedStorages !== 'undefined') {
      setPreferences(consentedStorages);
    }
  }, [consentedStorages, setPreferences]);

  const handleAccordion = (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  function handleChange(_event: React.SyntheticEvent, newValue: number): void {
    setValue(newValue);
  }

  function a11yProps(index: number): { id: string; 'aria-controls': string } {
    return {
      id: `cookie-config-tab-${index}`,
      'aria-controls': `cookie-config-tabpanel-${index}`,
    };
  }

  function handleAcceptance(): void {
    updateConsentPreferences(true, setPreferences, savePreferences);

    setIsConfigOpenedFromConsent(false);
    setIsConfigOpen(false);
  }

  function handleSavePreferences(): void {
    let consentType: Preference = 'none';

    if (preferences.includes('analytics_storage')) {
      consentType = 'analytics';
    }

    if (preferences.includes('ad_storage')) {
      consentType = 'ads';
    }

    if (preferences.includes('analytics_storage') && preferences.includes('ad_storage')) {
      consentType = 'all';
    }

    savePreferences(consentType);
    setIsConfigOpenedFromConsent(false);
    setIsConfigOpen(false);
  }

  function handlePreferenceSelection(type: string): void {
    const preferencesArray = preferences.split(',').filter((item) => item !== 'none');
    const index = preferencesArray.indexOf(type);

    if (index > -1) {
      preferencesArray.splice(index, 1);
    }

    if (index === -1) {
      preferencesArray.push(type);
    }

    if (preferencesArray.length === 0) {
      preferencesArray.push('none');
    }

    setPreferences(preferencesArray.join(','));
  }

  function handleCancel(): void {
    setIsConfigOpen(false);
    setIsConfigOpenedFromConsent(false);
    setIsConsentModalOpen(true);
  }

  function handleClose(): void {
    setIsConfigOpen(false);
  }

  const buttonText = isConfigOpenedFromConsent ? config.cookieConfigCancel : config.cookieConfigClose;
  const buttonClickHandler = isConfigOpenedFromConsent ? handleCancel : handleClose;

  return (
    <Dialog
      aria-describedby="keep-mounted-modal-description"
      aria-labelledby="keep-mounted-modal-title"
      open={isConfigOpen}
    >
      <DialogTitle id="alert-dialog-title">{config.cookieConfigTitle}</DialogTitle>
      <DialogContent>
        <PortableText components={customSerializers} value={config.cookieConfigText} />
        <Tabs
          TabIndicatorProps={{
            style: { display: 'none' },
          }}
          aria-label="select to know more about our cookies"
          className="mt-6"
          onChange={handleChange}
          value={value}
        >
          <Tab
            className={`rounded-t-md ${value === 0 ? 'bg-[#EEF1F3]' : ''}`}
            disableRipple
            label={config.cookieConfigTabTitle}
            {...a11yProps(0)}
          />
          <Tab
            className={`rounded-t-md ${value === 1 ? 'bg-[#EEF1F3]' : ''}`}
            disableRipple
            label={config.aboutCookiesTabTitle}
            {...a11yProps(1)}
          />
        </Tabs>
        <div
          aria-labelledby="cookie-config-tab-0"
          className="bg-[#EEF1F3]"
          hidden={value !== 0}
          id="cookie-config-tabpanel-0"
          role="tabpanel"
        >
          {value === 0 ? (
            <div className="p-3">
              <Accordion
                className="border-[0] border-b border-solid !border-white"
                expanded={expanded === 'essential-cookies'}
                onChange={handleAccordion('essential-cookies')}
              >
                <AccordionSummary
                  aria-controls="essential-cookies-content"
                  className="px-0 sm:px-4"
                  expandIcon={
                    expanded === `essential-cookies` ? (
                      <KeyboardArrowUpOutlinedIcon className="pr-3 w-8 h-5 text-primary" />
                    ) : (
                      <KeyboardArrowDownOutlinedIcon className="pr-3 w-8 h-5 text-primary" />
                    )
                  }
                  id="essential-cookies-header"
                >
                  <div className="text-base md:text-base">{config.essentialCookiesTitle}</div>
                  <ConfigSwitch checked className="ml-auto z-10 cursor-not-allowed" disabled />
                </AccordionSummary>
                <AccordionDetails>
                  <div className="mb-4">{config.essentialCookiesDescription}</div>
                  <ul>
                    {config.essentialCookies?.map((cookie) => (
                      <li className="flex w-full flex-col mb-2 px-4 py-2 bg-[#f9f9f9] rounded" key={cookie.name}>
                        <div className="flex w-full">
                          <span className="w-full text-sm font-bold">{cookie.name}</span>
                          <span className="flex items-center h-4 px-2 ml-auto whitespace-nowrap rounded text-xs text-black bg-[#e9e9ea]">
                            {cookie.expiry}
                          </span>
                        </div>
                        <div className="w-full text-sm">{cookie.description}</div>
                      </li>
                    ))}
                  </ul>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className="border-[0] border-b border-solid !border-white"
                expanded={expanded === 'analytic-cookies'}
                onChange={handleAccordion('analytic-cookies')}
              >
                <AccordionSummary
                  aria-controls="analytic-cookies-content"
                  className="px-0 sm:px-4"
                  expandIcon={
                    expanded === `analytic-cookies` ? (
                      <KeyboardArrowUpOutlinedIcon className="pr-3 w-8 h-5 text-primary" />
                    ) : (
                      <KeyboardArrowDownOutlinedIcon className="pr-3 w-8 h-5 text-primary" />
                    )
                  }
                  id="analytic-cookies-header"
                >
                  <div className="text-base md:text-base">{config.analyticsCookiesTitle}</div>
                  <ConfigSwitch
                    checked={preferences.includes('analytics_storage')}
                    className="ml-auto z-10"
                    inputProps={{
                      'aria-label': 'enable o disable this type of cookies',
                      onClick: (e) => {
                        e.stopPropagation();
                      },
                    }}
                    onChange={() => {
                      handlePreferenceSelection('analytics_storage');
                    }}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <div className="mb-4">{config.analyticsCookiesDescription}</div>
                  <ul>
                    {config.analyticsCookies?.map((cookie) => (
                      <li className="flex w-full flex-col mb-2 px-4 py-2 bg-[#f9f9f9] rounded" key={cookie.name}>
                        <div className="flex w-full">
                          <span className="w-full text-sm font-bold">{cookie.name}</span>
                          <span className="flex items-center h-4 px-2 ml-auto whitespace-nowrap rounded text-xs text-black bg-[#e9e9ea]">
                            {cookie.expiry}
                          </span>
                        </div>
                        <div className="w-full text-sm">{cookie.description}</div>
                      </li>
                    ))}
                  </ul>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className="border-[0] border-b border-solid !border-white"
                expanded={expanded === 'ad-cookies'}
                onChange={handleAccordion('ad-cookies')}
              >
                <AccordionSummary
                  aria-controls="ad-cookies-content"
                  className="px-0 sm:px-4"
                  expandIcon={
                    expanded === `ad-cookies` ? (
                      <KeyboardArrowUpOutlinedIcon className="pr-3 w-8 h-5 text-primary" />
                    ) : (
                      <KeyboardArrowDownOutlinedIcon className="pr-3 w-8 h-5 text-primary" />
                    )
                  }
                  id="ad-cookies-header"
                >
                  <div className="text-base md:text-base">{config.marketingCookiesTitle}</div>
                  <ConfigSwitch
                    checked={preferences.includes('ad_storage')}
                    className="ml-auto z-10"
                    inputProps={{
                      'aria-label': 'enable o disable this type of cookies',
                      onClick: (e) => {
                        e.stopPropagation();
                      },
                    }}
                    onChange={() => {
                      handlePreferenceSelection('ad_storage');
                    }}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <div className="mb-4">{config.marketingCookiesDescription}</div>
                  <ul>
                    {config.marketingCookies?.map((cookie) => (
                      <li className="flex w-full flex-col mb-2 px-4 py-2 bg-[#f9f9f9] rounded" key={cookie.name}>
                        <div className="flex w-full">
                          <span className="w-full text-sm font-bold">{cookie.name}</span>
                          <span className="flex items-center h-4 px-2 ml-auto whitespace-nowrap rounded text-xs text-black bg-[#e9e9ea]">
                            {cookie.expiry}
                          </span>
                        </div>
                        <div className="w-full text-sm">{cookie.description}</div>
                      </li>
                    ))}
                  </ul>
                </AccordionDetails>
              </Accordion>
            </div>
          ) : null}
        </div>
        <div
          aria-labelledby="cookie-config-tab-1"
          className="bg-[#EEF1F3]"
          hidden={value !== 1}
          id="cookie-config-tabpanel-1"
          role="tabpanel"
        >
          {value === 1 ? (
            <div className="wysiwyg p-4">
              <PortableText components={customSerializers} value={config.aboutCookiesText} />
            </div>
          ) : null}
        </div>
      </DialogContent>
      <DialogActions className="px-6 py-4">
        <Button className="sm:mr-auto px-5" onClick={buttonClickHandler}>
          {buttonText}
        </Button>
        <Button
          color="primary"
          disableElevation
          onClick={() => {
            handleSavePreferences();
          }}
          variant="text"
        >
          {config.cookieConfigSave}
        </Button>
        <Button
          color="primary"
          disableElevation
          onClick={() => {
            handleAcceptance();
          }}
          variant="contained"
        >
          {config.cookieConfigAcceptAll}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
