'use client';

import { useEffect } from 'react';
import NextScript from 'next/script';
import { usePathname, useSearchParams } from 'next/navigation';
import { useGtmEvent } from './use-gtm-event';
import type { EventArguments } from './types';

export default function Script({ containerId }: { containerId: string }): JSX.Element {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const pushEvent = useGtmEvent();

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    const url = `${pathname}${searchParams.toString()}`;
    const pageViewEvent: EventArguments = {
      event: 'pageview',
      page: url,
    };

    setDefaultGtmConsentMode();
    pushEvent(pageViewEvent);
  }, [pathname, pushEvent, searchParams]);

  return (
    <NextScript
      dangerouslySetInnerHTML={{
        __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${containerId}');
        `,
      }}
      id="google-tag-manager"
      strategy="lazyOnload"
    />
  );
}

/**
 * Set default GTM consent mode to 'denied' for ad_storage and analytics_storage
 * https://developers.google.com/tag-platform/security/guides/consent?consentmode=advanced#upgrade-consent-v2
 */
function setDefaultGtmConsentMode(): void {
  if (typeof window.dataLayer === 'undefined') return;

  gtag('consent', 'default', {
    ad_storage: 'denied',
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    analytics_storage: 'denied',
    wait_for_update: 500,
  });
  gtag('set', 'ads_data_redaction', true);
}

export const gtag: Gtag.Gtag = function (): void {
  // eslint-disable-next-line prefer-rest-params -- this is the recommended gtag function signature as per the GTM docs
  window.dataLayer?.push(arguments as unknown as { [key: string]: unknown });
};
