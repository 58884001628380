'use client';

import { createContext, useContext, useState } from 'react';
import type { Dispatch, ReactNode, SetStateAction } from 'react';

type Context = {
  isConfigOpen: boolean;
  setIsConfigOpen: Dispatch<SetStateAction<boolean>>;
  isConsentModalOpen: boolean;
  setIsConsentModalOpen: Dispatch<SetStateAction<boolean>>;
  isConfigOpenedFromConsent: boolean;
  setIsConfigOpenedFromConsent: Dispatch<SetStateAction<boolean>>;
  preferences: string;
  setPreferences: Dispatch<SetStateAction<string>>;
};

type Props = {
  children: ReactNode;
};

const defaultContext = {
  isConfigOpen: false,
  setIsConfigOpen: (_isConfigOpen: boolean): void => undefined,
  isConsentModalOpen: false,
  setIsConsentModalOpen: (_isConsentModalOpen: boolean): void => undefined,
  isConfigOpenedFromConsent: false,
  setIsConfigOpenedFromConsent: (_isConfigOpenedFromConsent: boolean): void => undefined,
  preferences: 'none',
  setPreferences: (_preferences: string): void => undefined,
};
const GtmContext = createContext(defaultContext);

export function GtmProvider({ children }: Props): JSX.Element {
  const [isConfigOpen, setIsConfigOpen] = useState(false);
  const [isConsentModalOpen, setIsConsentModalOpen] = useState(true);
  const [isConfigOpenedFromConsent, setIsConfigOpenedFromConsent] = useState(false);
  const [preferences, setPreferences] = useState('none');

  return (
    <GtmContext.Provider
      value={{
        isConfigOpen,
        setIsConfigOpen,
        isConsentModalOpen,
        setIsConsentModalOpen,
        isConfigOpenedFromConsent,
        setIsConfigOpenedFromConsent,
        preferences,
        setPreferences,
      }}
    >
      {children}
    </GtmContext.Provider>
  );
}

export function useCookieConfigModal(): Context {
  return useContext(GtmContext) as Context;
}
