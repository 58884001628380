import Skeleton from '@mui/material/Skeleton';

export default function ResultSkeleton(): JSX.Element {
  return (
    <div className="flex py-3 border-[0] border-b border-solid border-accent-3">
      <div className="flex justify-center items-start w-11">
        <Skeleton height={22} variant="circular" width={22} />
      </div>
      <div className="flex flex-col flex-1">
        <Skeleton height="22" variant="rounded" width={200} />
        <Skeleton className="my-1 text-base" variant="text" width={280} />
        <div className="flex">
          <Skeleton className="mr-2" height={16} variant="rounded" width={16} />
          <Skeleton className="text-base" height={16} variant="rounded" width={130} />
        </div>
      </div>
    </div>
  );
}
