'use client';

import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { PortableText } from '@portabletext/react';
import { customSerializers } from '../custom-sanity-serializers';
import { useConsentPreferences, updateConsentPreferences } from './use-consent-preferences';
import { useCookieConfigModal } from './context';
import type { GtmConfig } from './types';

type Props = {
  config: GtmConfig;
};

export default function ConsentModal({ config }: Props): JSX.Element | null {
  const { isConsentModalOpen, setIsConfigOpen, setIsConsentModalOpen, setIsConfigOpenedFromConsent, setPreferences } =
    useCookieConfigModal();
  const [showConsent, setShowConsent] = useState(false);
  const [consentedStorages, savePreferences] = useConsentPreferences();

  useEffect(() => {
    setShowConsent(typeof consentedStorages === 'undefined');
  }, [consentedStorages]);

  function handleResponse(hasAgreed: boolean): void {
    updateConsentPreferences(hasAgreed, setPreferences, savePreferences);

    setIsConsentModalOpen(false);
  }

  function handleConfig(): void {
    setIsConfigOpen(true);
    setIsConfigOpenedFromConsent(true);
    setIsConsentModalOpen(false);
  }

  if (!showConsent) {
    return null;
  }

  return (
    <Dialog
      aria-describedby="keep-mounted-modal-description"
      aria-labelledby="keep-mounted-modal-title"
      open={isConsentModalOpen}
    >
      <DialogTitle id="alert-dialog-title">{config.consentPopupTitle}</DialogTitle>
      <DialogContent>
        <PortableText components={customSerializers} value={config.consentPopupText} />
      </DialogContent>
      <DialogActions className="px-6 py-4">
        <div className="flex w-full flex-col-reverse sm:flex-row gap-y-2 sm:gap-y-0 sm:gap-x-3">
          <Button
            className="sm:mr-auto px-5"
            onClick={() => {
              handleConfig();
            }}
            variant="text"
          >
            {config.configureConsentButton}
          </Button>
          <Button
            color="primary"
            disableElevation
            onClick={() => {
              handleResponse(false);
            }}
            variant="contained"
          >
            {config.rejectConsentButton}
          </Button>
          <Button
            color="primary"
            disableElevation
            onClick={() => {
              handleResponse(true);
            }}
            variant="contained"
          >
            {config.acceptConsentButton}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}
