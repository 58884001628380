'use client';

import { useEffect, useRef } from 'react';
import type { MouseEventHandler } from 'react';
import { Button, Collapse } from '@mui/material';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { LogoUriachColor, LogoUriachWhite } from './assets';

type CorporateFooterButtonProps = {
  className?: string;
  onClick: MouseEventHandler;
  isOpen: boolean;
  variant: 'color-logo' | 'white-logo';
};

export function CorporateFooterButton({
  className,
  onClick,
  isOpen,
  variant,
}: CorporateFooterButtonProps): JSX.Element {
  const arrowIcons = variant === 'white-logo' ? 'fill-white' : 'text-primary';
  return (
    <Button
      aria-controls="corporate-footer"
      aria-expanded={isOpen}
      aria-label="Abrir/cerrar pie de página corporativo"
      className={`pl-3 pr-2 pt-2.5 pb-2 rounded-b-none rounded-t-lg min-w-[8rem] max-w-[8rem] md:min-w-[9.125rem] md:max-w-[9.125rem] md:max-h-[2.75rem] ${className}`}
      color="primary"
      disableElevation
      endIcon={
        isOpen ? (
          <KeyboardArrowUpOutlinedIcon className={arrowIcons} />
        ) : (
          <KeyboardArrowDownOutlinedIcon className={arrowIcons} />
        )
      }
      onClick={onClick}
      variant="contained"
    >
      {variant === 'white-logo' ? (
        <LogoUriachWhite className="grow h-[1.5rem] md:h-[1.625rem]" />
      ) : (
        <LogoUriachColor className="grow h-[1.5rem] md:h-[1.625rem]" />
      )}
    </Button>
  );
}

type CorporateFooterPanelProps = {
  bgColor: string;
  color: string;
  isOpen: boolean;
};

export function CorporateFooterPanel({ bgColor, color, isOpen }: CorporateFooterPanelProps): JSX.Element {
  const panelRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (!isOpen) return;

    setTimeout(() => {
      panelRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }, 250);
  }, [isOpen]);

  return (
    <Collapse collapsedSize={10} in={isOpen} ref={panelRef}>
      <div
        className={`flex justify-center px-4 md:px-10 lg:px-16 ${bgColor} corporate-footer-panel`}
        id="corporate-footer"
      >
        <div className={`flex w-full flex-col md:flex-row max-w-screen-xl pt-7 pb-5 md:py-7 lg:py-9 ${color}`}>
          <div className="flex grow justify-center md:order-2 gap-x-6 pb-6">
            <a className={`text-xl font-bold ${color}`} href="https://www.uriach.com/">
              Uriach.com
            </a>
            <ul className="flex gap-x-6">
              <li>
                <a
                  aria-label="Síguenos en Twitter"
                  className={color}
                  href="https://twitter.com/Uriach"
                  rel="nofollow noreferrer"
                  target="_blank"
                >
                  <TwitterIcon className="w-5 h-5" />
                </a>
              </li>
              <li>
                <a
                  aria-label="Síguenos en LinkedIn"
                  className={color}
                  href="https://www.linkedin.com/company/grupo-uriach/"
                  rel="nofollow noreferrer"
                  target="_blank"
                >
                  <LinkedInIcon className="w-5 h-5" />
                </a>
              </li>
              <li>
                <a
                  aria-label="Síguenos en YouTube"
                  className={color}
                  href="https://www.youtube.com/channel/UC5aWd_9D6q1rMFnH7rDdBBg"
                  rel="nofollow noreferrer"
                  target="_blank"
                >
                  <YouTubeIcon className="w-5 h-5" />
                </a>
              </li>
            </ul>
          </div>
          <div className="flex md:order-1 grow">
            <LocationOnIcon className="hidden md:block w-5 h-5 pr-1" />
            <div className="flex-col grow">
              <p className="text-sm leading-6 text-center md:text-left">
                <LocationOnIcon className="md:hidden w-4 h-5" /> Edifici Green, Avinguda de la Generalitat 163-167, Sant
                Cugat del Vallès · 08174 · Barcelona · Spain
              </p>
              <p className="text-xs text-center md:text-left font-semibold leading-6 opacity-70">
                Uriach.com Todos los derechos reservados.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Collapse>
  );
}
