import type { EventArguments } from './types';

declare const window: Window & { dataLayer: { [key: string]: unknown }[] };

export function useGtmEvent(): (eventArguments: EventArguments) => number {
  function pushEvent(eventArguments: EventArguments): number {
    const { event: eventName, ...eventValues } = eventArguments;

    return window.dataLayer.push({
      event: eventName,
      ...eventValues,
    });
  }

  return pushEvent;
}
